module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-anchor-links@1.2.1/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-80,"duration":2000},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.3_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W3N6X9L","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-analytics@5.13.1_gatsby@5.13.3_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-167847506-2","enableWebVitalsTracking":false,"head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.3_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stay or Go","short_name":"Stay or Go","description":"Stay or Go","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icons":[{"src":"/logo192.png","sizes":"192x192","type":"image/png"},{"src":"/logo512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-omni-font-loader@2.0.2_gatsby@5.13.3_react-helmet@6.1.0/node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"custom":[{"name":["arboria-bold","arboria-book","arboria-medium","arboria-light"],"file":"/fonts/fonts.css"}]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.13.1_gatsby@5.13.3/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.stayorgo.com.au"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.3_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.5.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
