import React, { createContext, useContext, useEffect, useState } from 'react'
import { apis as API } from '@sog/sdk'

type PropertyType = {
  loans: any[]
  [key: string]: any
}

type OfferType = {
  offer: {
    properties: PropertyType[]
    [key: string]: any
  }
  loans: any[]
  [key: string]: any
}

type RetentionEventType = {
  properties: PropertyType[]
  offers: OfferType
  eventId: any
  status: string
}

type UserDetails = {
  firstName: string
  lastName: string
  mobileNumber: string
  email: string
  viewed?: OfferType[]
  retentionEvents?: RetentionEventType[]
  [key: string]: any
}

type AuthContextType = {
  userDetails: UserDetails | null
  firstLoad: boolean
  isRefetching: boolean
  error: any
  refetchUserDetails: () => void
  loginUser: (data: any, onSuccess?: ((response: any) => any) | undefined, onError?: ((error: any) => any) | undefined) => void
  loginUserVerifyOtp: (data: any, onSuccess?: ((response: any) => any) | undefined, onError?: ((error: any) => any) | undefined) => void
  loginUserResendOtp: (data: any, onSuccess?: ((response: any) => any) | undefined, onError?: ((error: any) => any) | undefined) => void
  signupUser: (data: any, onSuccess?: ((response: any) => any) | undefined, onError?: ((error: any) => any) | undefined) => void
  signupUserVerifyEmail: (data: any, onSuccess?: ((response: any) => any) | undefined, onError?: ((error: any) => any) | undefined) => void
  signupUserVerifyMobile: (data: any, onSuccess?: ((response: any) => any) | undefined, onError?: ((error: any) => any) | undefined) => void
  signupUserResendEmailOtp: (data: any, onSuccess?: ((response: any) => any) | undefined, onError?: ((error: any) => any) | undefined) => void
  signupUserResendMobileOtp: (data: any, onSuccess?: ((response: any) => any) | undefined, onError?: ((error: any) => any) | undefined) => void
  logoutUser: (data: any, onSuccess?: ((response: any) => any) | undefined, onError?: ((error: any) => any) | undefined) => void
}

const AuthContext = createContext<AuthContextType|undefined>(undefined)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (typeof context === 'undefined') {
    throw new Error("useAuth must be within TodoProvider")
  }
  return context
}

export default AuthContext

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [userDetails, setUserDetails] = useState<UserDetails|null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isRefetching, setIsRefetching] = useState(true)
  const [error, setError] = useState<any>(null)

  // uses cookie as auth, so no need to pass auth props
  const loadUserDetails = () => {
    setIsRefetching(true)
    setError(null)
    API.getUserDetails({}, (response) => {
      if (response.firstName && response.lastName && response.email && response.mobileNumber) {
        setUserDetails({...response, status: undefined})
      } else {
        setError({ status: 200, message: "Server returned data but was missing expected detail!" })
        setUserDetails(null)
      }
      setIsLoading(false)
      setIsRefetching(false)
    }, (error) => {
      // setUserDetails(null)
      setError(error)
      setIsLoading(false)
      setIsRefetching(false)
    })
  }

  // first load: get user details from server
  useEffect(() => {
    loadUserDetails()
  }, [])

  const loginUser = (data: any, onSuccess?: (response: any) => any, onError?: (error: any) => any) => {
    API.signin(data, (response: any) => {
      loadUserDetails()
      onSuccess && onSuccess(response)
    }, onError)
  }

  const loginUserVerifyOtp = (data: any, onSuccess?: (response: any) => any, onError?: (error: any) => any) => {
    API.signinVerifyOtp(data, (response: any) => {
      onSuccess && onSuccess(response)
      loadUserDetails()
    }, onError)
  }

  const loginUserResendOtp = (data: any, onSuccess?: (response: any) => any, onError?: (error: any) => any) => {
    API.signinResendOtp(data, (response: any) => {
      onSuccess && onSuccess(response)
    }, onError)
  }

  const signupUser = (data: any, onSuccess?: (response: any) => any, onError?: (error: any) => any) => {
    API.signup(data, (response: any) => {
      onSuccess && onSuccess(response)
    }, onError)
  }

  const signupUserVerifyEmail = (data: any, onSuccess?: (response: any) => any, onError?: (error: any) => any) => {
    API.signupVerifyEmail(data, (response: any) => {
      onSuccess && onSuccess(response)
    }, onError)
  }

  const signupUserVerifyMobile = (data: any, onSuccess?: (response: any) => any, onError?: (error: any) => any) => {
    API.signupVerifyMobile(data, (response: any) => {
      onSuccess && onSuccess(response)
      loadUserDetails()
    }, onError)
  }

  const signupUserResendEmailOtp = (data: any, onSuccess?: (response: any) => any, onError?: (error: any) => any) => {
    API.signupResendEmailOtp(data, (response: any) => {
      onSuccess && onSuccess(response)
    }, onError)
  }

  const signupUserResendMobileOtp = (data: any, onSuccess?: (response: any) => any, onError?: (error: any) => any) => {
    API.signupResendMobileOtp(data, (response: any) => {
      onSuccess && onSuccess(response)
    }, onError)
  }

  const logoutUser = (data: any, onSuccess?: (response: any) => any, onError?: (error: any) => any) => {
    API.signout(data, (response: any) => {
      onSuccess && onSuccess(response)
      setUserDetails(null)
    }, onError)
  }

  const contextValue: AuthContextType = {
    userDetails,
    firstLoad: isLoading,
    isRefetching,
    error,
    refetchUserDetails: loadUserDetails,
    loginUser,
    loginUserVerifyOtp,
    loginUserResendOtp,
    signupUser,
    signupUserVerifyEmail,
    signupUserVerifyMobile,
    signupUserResendEmailOtp,
    signupUserResendMobileOtp,
    logoutUser,
  }

  return <AuthContext.Provider value={contextValue}>
    {children}
  </AuthContext.Provider>
}